import { Action } from '@ngrx/store';

export enum AppActionTypes {
  NavigateToDashboardAction = '[App] NavigateToDashboardAction',
  NavigateToUsersAction = '[App] NavigateToUsersAction',
  NavigationCompleteAction = '[App] NavigationCompleteAction'
}

export class NavigateToDashboardAction implements Action {
  readonly type = AppActionTypes.NavigateToDashboardAction;
}


export class NavigateToUsersAction implements Action {
  readonly type = AppActionTypes.NavigateToUsersAction;
}

export class NavigationCompleteAction implements Action {
  readonly type = AppActionTypes.NavigationCompleteAction;
}

export type AppActions =
  NavigateToDashboardAction |
  NavigateToUsersAction |
  NavigationCompleteAction;
