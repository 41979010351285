import { Action } from '@ngrx/store';
import { AppActions, AppActionTypes } from './app.actions';

export interface State {
  currentRoute: string;
}

export const initialState: State = {
  currentRoute: '/dashboard'
};

export function reducer(state = initialState, action: AppActions): State {
  switch (action.type) {

    case AppActionTypes.NavigateToDashboardAction:
      return { ...state, currentRoute: '/dashboard' };

    case AppActionTypes.NavigateToUsersAction:
      return { ...state, currentRoute: '/user' }; 

    default:
      return state;
  }
}
