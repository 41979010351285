import {switchMap, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar'; 
import { environment } from '../../environments/environment'; 

@Injectable({
  providedIn: 'root',
})
export class SharedService {
   
  constructor(
    public snackBar: MatSnackBar  ) { 
  }


  // Simple validation: (email) @ (domain) . (domain)
  validateEmail(email: string): boolean {
    if (email == null || !email.match(/^\S+@\S+\.\S+$/)) {
      this.showMessages('ERROR', ['Please provide a valid email address!']);
      return false;
    }
    return true;
  }

  // Displays messages/errors
  showMessages(type: string, messages: string[], config?: MatSnackBarConfig) {

    if (messages.length < 1) return;
    if (messages[0] === undefined || messages[0].length < 1) return;

    // Default Settings for snackbar
    if (config == null) {
      config = new MatSnackBarConfig();
      config.duration = 5000;
    }

    // Attach data 
    config.data = {
      messages: messages,
      type: type
    };

    // Show snackbar // All config properties you can find here: https://material.angular.io/components/snack-bar/api#MatSnackBarConfig
    this.snackBar.openFromComponent(SnackbarComponent, config);
  } 
}


