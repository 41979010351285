import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'shared-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent {

  // Message style
  errorClass: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public snackBarRef: MatSnackBarRef<SimpleSnackBar>) {

    // Change style based on the message type
    this.errorClass = data['type'] === 'ERROR' ? 'error' : data['type'] === 'INFO' ? 'info' : 'success';
  }

  hideMessages() {
    this.snackBarRef.dismiss();
  }
}
