import { Action, createFeatureSelector, createSelector } from '@ngrx/store';
import { UserActions, UserActionTypes } from './user.actions';
import { UserModel } from "./user.model";

export interface State {
  users: UserModel[];
  currentUser: UserModel;
  isLoading: boolean;
  errors: string[];
}

export const initialState: State = {
  users: [],
  currentUser: null,
  isLoading: false,
  errors: []
};

export function reducer(state = initialState, action: UserActions): State {
  let users = [];
  switch (action.type) {

    case UserActionTypes.GetUsersAction:
      return { ...state, isLoading: true };
    case UserActionTypes.ReceivedUsersAction:
      return { ...state, isLoading: false, users: action.users }
    case UserActionTypes.NewUserAction:
      return { ...state, currentUser: action.user }
    case UserActionTypes.ModifyUserAction:
      return { ...state, currentUser: action.user }
    case UserActionTypes.CreateUserAction:
      return { ...state, isLoading: true };
    case UserActionTypes.CreatedUserAction:
      users = [...state.users];
      users.push(action.user);
      return { ...state, users, isLoading: false, currentUser: null };
    case UserActionTypes.SaveUserAction:
      return { ...state, isLoading: true };
    case UserActionTypes.SavedUserAction:
      users = [...state.users];
      const index = users.findIndex(x => x.id === action.user.id);
      users[index] = action.user;
      return { ...state, users, isLoading: false, currentUser: null };
    case UserActionTypes.CancelUserAction:
      return { ...state, currentUser: null, isLoading: false }
    case UserActionTypes.ErrorUserAction:
      return { ...state, errors: action.errors, isLoading: false }
    default:
      return state;
  }
}



// Constants for selectors
export const isLoading = (state: State) => state.isLoading;
export const users = (state: State) => state.users;
export const currentUser = (state: State) => state.currentUser;
export const errors = (state: State) => state.errors;

// Selectors - Good for optimization
export const getState = createFeatureSelector<State>('user');

export const getIsLoading = createSelector(getState, isLoading);
export const getUsers = createSelector(getState, users);
export const getCurrentUser = createSelector(getState, currentUser);
export const getErrors = createSelector(getState, errors);
