import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { UserActionTypes } from './user.actions';
import { UserService } from "./user.service";
import * as UserActions from './user.actions';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { UserModel } from "./user.model";

@Injectable()
export class UserEffects {

  @Effect()
  getUsers$ = this.actions$.ofType(UserActionTypes.GetUsersAction).pipe(
    mergeMap((action: UserActions.GetUsersAction) =>
      this.userService.getUsers(action.searchterm, action.count).pipe(
        map((users: UserModel[]) =>
          new UserActions.ReceivedUsersAction(users)
        )
      )
    )
  );

  @Effect()
  createUser$ = this.actions$.ofType(UserActionTypes.CreateUserAction).pipe(
    mergeMap((action: UserActions.CreateUserAction) =>
      this.userService.createUser(action.user, action.password).pipe(
        map((user: UserModel) =>
          user.isSuccess ? new UserActions.CreatedUserAction(user) : new UserActions.ErrorUserAction(user.errors)
        )
      )
    )
  );

  @Effect()
  saveUser$ = this.actions$.ofType(UserActionTypes.SaveUserAction).pipe(
    mergeMap((action: UserActions.CreateUserAction) =>
      this.userService.saveUser(action.user, action.password).pipe(
        map((user: UserModel) =>
          user.isSuccess ? new UserActions.SavedUserAction(user) : new UserActions.ErrorUserAction(user.errors)
        )
      )
    )
  );


  constructor(private actions$: Actions, public userService: UserService) { }
}
