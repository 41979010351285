import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from "../shared/shared.service";
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthClaim } from "./authclaim.model";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public key: string;
  public isLoggedIn: boolean;
  public appAccess: AuthClaim[];
  public name: string;
  public email: string;

  constructor(private http: HttpClient, private sharedService: SharedService, private router: Router) {


  }

  login(username: string, password: string) {

    const formData: FormData = new FormData();

    formData.append("UserName", username);
    formData.append("Password", password);
    formData.append("button", "login");
    formData.append("ReturnUrl", (<any>window).returnUrl);
    formData.append("RememberLogin", "false");

    // Return Observable
    this.http.post('account/login', formData).subscribe((x: any) => {
      if (x.success) {
        if (x.returnUrl != '' && x.returnUrl != null) {
          // Return to provider
          window.location.href = x.returnUrl;
        } else {
          this.setAppAccess(x.claims);
        }
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
        // Add error to the errors array and show them
        const errors: string[] = [];
        errors.push('Invalid username or password!');
        this.sharedService.showMessages('ERROR', errors);
      }
    });
  }


  externalLogin() { 
    (<any>window).location.href = '/account/ExternalLogin?provider=oidc&returnUrl=' + (<any>window).returnUrl;
  }


  resetPassword(email: string): Observable<any> {
    return this.http.post("/account/resetPassword", { email }, this.getHttpOptions());
  }


  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    return this.http.post("/account/changePassword", { oldPassword, newPassword }, this.getHttpOptions());
  }

  changePasswordWithKey(key: string, password: string): Observable<any> {
    return this.http.post("/account/changePasswordWithKey", { key, password }, this.getHttpOptions());
  }

  setAppAccess(claims: any) {
    this.appAccess = [];
    if (claims == null) return;
    // Store App Access
    for (let claim of claims) {
      if (claim.type.indexOf('Access.') > -1) {
        var name = claim.value.split('|')[0];
        var value = claim.value.split('|')[1];
        this.appAccess.push(new AuthClaim(name, value));
      }
      else if (claim.type == 'given_name') {
        this.name = claim.value;
      } else if (claim.type == 'email') {
        this.email = claim.value;
      }
    }
    if (this.appAccess.length > 1) {
      this.router.navigate(['/access']);
    } else {
      if (this.appAccess[0].value.indexOf('http') > -1)
        window.location.href = this.appAccess[0].value;
      else
        this.router.navigate([this.appAccess[0].value]);
    }
  }

  // Build headers for the http request and return.
  // TOKEN will be added by Interceptor
  getHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
    };
  }

  checkIsLoggedIn() {
    this.http.get('account/isloggedin').pipe(
      catchError(() => this.router.navigate(['/auth']))
    ).subscribe((x: any) => {
      if (x != null) {
        this.setAppAccess(x.claims);
        this.isLoggedIn = true;
        var changePassword = (<any>window).changePassword != null && (<any>window).changePassword != '';

        if (changePassword) {
          this.router.navigate(['/changepassword']);
        }
      }
    });
  }
}
