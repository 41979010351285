import { Component, OnInit } from '@angular/core';
import { SharedService } from "../../shared/shared.service";
import { Observable } from 'rxjs';
import { AuthService } from "../auth.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
   
  email: string;

  constructor(private authService: AuthService, private sharedService: SharedService, private router: Router) { }

  ngOnInit() {

  }
   
  // Send htttp request
  resetPassword() {
    if (this.sharedService.validateEmail(this.email)) {
      this.authService.resetPassword(this.email).subscribe(() => this.sharedService.showMessages("INFO", ["You will shortly recieve an email for instructions on how to reset your password."]));
      this.router.navigate(['/auth']);
    }
  }

  // Sends beck to login oage
  cancelPasswordReset() {
    this.router.navigate(['/auth']);
  }

  // Displays messages/errors types are: ERROR. INFO, SUCCESS
  showMessages(type: string, messages: string[]) {
    // You can additional logic here
    this.sharedService.showMessages(type, messages);
  }

  needHelp() {
    this.showMessages('INFO', ['If you are having problems with recovering your password, please contact support.']);
  }

}
