import { Injectable } from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
  } from '@angular/router'; 
import {AuthService} from "../auth/auth.service";

@Injectable()

export class AdminAuthCheck implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url;

    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    if (this.authService.isLoggedIn) { return true; }
     
    // Navigate to the login page with extras
    this.router.navigate(['/auth']);
    return true;
  }
}
