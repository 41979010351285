export class UserModel {
  public id: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public userName: string = '';
  public email: string = '';
  public phoneNumber: string = '';
  public faxNumber: string = '';
  public apps: Map<string, boolean> = new Map<string, boolean>();

  public isSuccess: boolean;
  public errors: string[];

  constructor(init?: Partial<UserModel>) {
    Object.assign(this, init);
  }

}
