import { Component, OnInit } from '@angular/core';
import { AuthClaim } from '../auth/authclaim.model';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.scss']
})
export class AccessComponent implements OnInit {

  appAccess: AuthClaim[];
  name: string;
  email: string;  

  constructor(private authService: AuthService, private router: Router) {
    this.appAccess = authService.appAccess.filter(x => x.value != null).sort((a, b) => a.type > b.type ? 1 : -1);;
    this.name = authService.name;
    this.email = authService.email;
  }

  ngOnInit() {
  }

  openUrl(isUrl: boolean, link: string) {
    if (isUrl)
      document.location.href = link;
    else this.router.navigateByUrl(link);    
  }
}
