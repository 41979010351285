export class AuthClaim {
  color: string;
  icon: string;
  isUrl: boolean;

  constructor(public type: string, public value: string) {
    this.isUrl = this.checkUrl();
    this.color = this.getColor();
    this.icon = this.getIcon();
  }

  checkUrl(): boolean {
    if (this.value == null) return false;
    return this.value.indexOf("http") > -1;
  }

  getColor() {
    if (this.isMatch('rpm')) return 'purple';
    else if (this.isMatch('accounting')) return 'blue';
    else if (this.isMatch('workflow')) return 'yellow';
    else if (this.isMatch('user')) return 'red';
    else if (this.isMatch('dmu')) return 'green';
    else if (this.isMatch('agency')) return 'orange';
    else return 'grey';
  }

  getIcon(): string {
    let icon = '';
    if (this.isMatch('rpm')) icon = 'business';
    else if (this.isMatch('accounting')) icon = 'monetization_on';
    else if (this.isMatch('workflow')) icon = 'swap_calls';
    else if (this.isMatch('user')) icon = 'person';
    else if (this.isMatch('dmu')) icon = '';
    else if (this.isMatch('agency')) icon = '<img class="icon" src="../../assets/img/icon-businessman-white.svg" height="45">';
    else icon = 'link';
    return icon === '' ? 'link' : icon;
  }

  isMatch(search: string) {
    if (!this.type) return false;
    return this.type.toLowerCase().indexOf(search.toLowerCase()) > -1;
  }
}
