import { Component, OnInit } from '@angular/core';
import { AuthService } from "./auth/auth.service";
import { Observable } from "rxjs";
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'Bass Underwriters';


  constructor(private authService: AuthService, private router: Router) {

  }


  ngOnInit() {
    var resetPassword = (<any>window).resetPasswordKey != null && (<any>window).resetPasswordKey != '';

    if (!resetPassword)
      this.authService.checkIsLoggedIn();
    else {
      this.authService.key = (<any>window).resetPasswordKey;
      this.router.navigate(['/resetpassword']);
    }
  }
}
