import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { UserModel } from "./user.model";
import { AuthService } from "../auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient, private authService: AuthService) { }

  getUsers(searchterm: string, count: number): Observable<UserModel[]> {
    return this.http.post("/user/getusers", { searchterm: searchterm, count: count }, this.authService.getHttpOptions()).pipe(
      map((response: any) => [...response.map(x => new UserModel(x))])
    );
  }

  createUser(user: UserModel, password: string): Observable<UserModel> { 
    return this.http.post("/user/createUser", { ...user,  password }, this.authService.getHttpOptions()).pipe(
      map((response: any) => new UserModel(response)),
      catchError((e: HttpErrorResponse) => Observable.throw(<string[]>JSON.parse(e.error.Message)))
    );
  }

  saveUser(user: UserModel, password: string): Observable<UserModel> {
    return this.http.post("/user/saveUser", { ...user, password }, this.authService.getHttpOptions()).pipe(
      map((response: any) => new UserModel(response)),
      catchError((e: HttpErrorResponse) => Observable.throw(<string[]>JSON.parse(e.error.Message)))
    );
  }
}
