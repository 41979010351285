import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { AppActionTypes } from './app.actions';
import * as AppActions from './app.actions';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AppEffects {

  @Effect()
  navigateToDashboardAction$ = this.actions$.ofType(AppActionTypes.NavigateToDashboardAction)
      .pipe(map(() => {
      this.router.navigate(['/dashboard']);
      return new AppActions.NavigationCompleteAction();
    }));


  @Effect()
  navigateToUsersAction$ = this.actions$.ofType(AppActionTypes.NavigateToUsersAction)
    .pipe(map(() => {
      this.router.navigate(['/user']);
      return new AppActions.NavigationCompleteAction();
    }));


  constructor(private actions$: Actions, private router: Router) {}
}
