import { Action } from '@ngrx/store';

export enum AuthActionTypes {
  ChangePasswordCallAction = '[Auth] ChangePasswordCallAction',
  ChangedPasswordCallAction = '[Auth] ChangedPasswordCallAction',
  ChangePasswordWithKeyAction = '[Auth] ChangePasswordWithKeyAction',
  ChangedPasswordWithKeyAction = '[Auth] ChangedPasswordWithKeyAction',
  LogOutAction = '[Auth] LogOutAction',
  LoggedOutAction = '[Auth] LoggedOutAction',
  ForgotPasswordAction = '[Auth] ForgotPasswordAction',
  AuthErrorAction = '[Auth] AuthErrorAction'
}

export class ChangePasswordCallAction implements Action {
  readonly type = AuthActionTypes.ChangePasswordCallAction;
  constructor(public oldPassword: string, public newPassword: string) { }
}

export class ChangedPasswordCallAction implements Action {
  readonly type = AuthActionTypes.ChangedPasswordCallAction; 
}

export class ChangePasswordWithKeyAction implements Action {
  readonly type = AuthActionTypes.ChangePasswordWithKeyAction;
  constructor(public key: string, public password: string) { }
}

export class ChangedPasswordWithKeyAction implements Action {
  readonly type = AuthActionTypes.ChangedPasswordWithKeyAction; 
}

export class LogOutAction implements Action {
  readonly type = AuthActionTypes.LogOutAction;
}

export class LoggedOutAction implements Action {
  readonly type = AuthActionTypes.LoggedOutAction;
}

export class ForgotPasswordAction implements Action {
  readonly type = AuthActionTypes.ForgotPasswordAction;
}

export class AuthErrorAction implements Action {
  readonly type = AuthActionTypes.AuthErrorAction;
  constructor(public errors: string[]) { }
}

export type AuthActions =
  ChangePasswordCallAction |
  ChangedPasswordCallAction |
  LogOutAction |
  ForgotPasswordAction |
  ChangePasswordWithKeyAction |
  ChangedPasswordWithKeyAction |
  AuthErrorAction |
  LoggedOutAction;
