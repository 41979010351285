import { Component, OnInit } from '@angular/core';
import { AuthService } from "./auth.service";
import { Router } from '@angular/router';

@Component({
  selector: 'auth',
  template: '',
  //templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.authService.externalLogin();
  }

  tryLogIn(username: string, password: string) {
    this.authService.login(username, password);
  }

  forgotPassword() {
    this.router.navigate(["/forgotpassword"]);
  }
}
