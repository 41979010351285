import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as fromApp from '../app.reducer';
import * as fromUser from '../user/user.reducer';
import * as fromAuth from '../auth/auth.reducer';

export interface AppState {

  app: fromApp.State;
  user: fromUser.State;
  auth: fromAuth.State;
}

export const reducers: ActionReducerMap<AppState> = {

  app: fromApp.reducer,
  user: fromUser.reducer,
  auth: fromAuth.reducer,
};


export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
