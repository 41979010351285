import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import * as fromUser from '../user.reducer';
import * as UserActions from '../user.actions';

import { UserModel } from '../user.model';

@Component({
  selector: 'user-modify',
  templateUrl: './user-modify.component.html',
  styleUrls: ['./user-modify.component.scss']
})
export class UserModifyComponent implements OnInit {

  user$: Observable<UserModel>;
  password: string;
  validationErrors: string[] = [];

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    this.password = '';
    this.user$ = this.store.select(fromUser.getCurrentUser);
  }

  backToUsers() {
    this.store.dispatch(new UserActions.CancelUserAction());
  }

  save(user: UserModel) {
    if (user.id == '') {
      this.store.dispatch(new UserActions.CreateUserAction(user, this.password));
    } else {
      this.store.dispatch(new UserActions.SaveUserAction(user, this.password));
    }
  }
}
