import { Routes } from '@angular/router';

import { AdminAuthCheck } from "./shared/admin-auth-check";
import { AuthCheck } from "./shared/auth-check";

import { AuthComponent } from './auth/auth.component';
import { ClientComponent } from './client/client.component';
import { UserComponent } from './user/user.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AccessComponent } from './access/access.component';
import { AppComponent } from "./app.component";
import { ResetPasswordComponent } from "./auth/reset-password/reset-password.component";
import { ForgotPasswordComponent } from "./auth/forgot-password/forgot-password.component";
import { ChangePasswordComponent } from "./auth/change-password/change-password.component";

export const routes: Routes = [
  {
    path: '',
    component: AppComponent,
  },
  {
    path: 'auth/:redirectTo',
    component: AuthComponent,
  },
  {
    path: 'auth',
    component: AuthComponent,
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent,
  },
  {
    path: 'changepassword',
    canActivate: [AuthCheck],
    component: ChangePasswordComponent,
  },
  {
    path: 'resetpassword',
    component: ResetPasswordComponent,
  }, 
  {
    path: 'access',
    component: AccessComponent,
  },
  {
    path: 'client',
    canActivate: [AdminAuthCheck],
    component: ClientComponent,
  },
  {
    path: 'user',
    canActivate: [AdminAuthCheck],
    component: UserComponent,
  },
  {
    path: 'dashboard',
    canActivate: [AdminAuthCheck],
    component: DashboardComponent,
  }
];
