import { Component, OnInit } from '@angular/core';
import { UserAppModel } from './user-app.model';
import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';

import { UserModel } from '../../user.model';

import * as fromUser from '../../user.reducer';

@Component({
  selector: 'user-app',
  templateUrl: './user-app.component.html',
  styleUrls: ['./user-app.component.scss']
})
export class UserAppComponent implements OnInit {

  apps: UserAppModel[] = [];

  user$: Observable<UserModel>;

  constructor(private store: Store<AppState>) {
    this.apps.push(new UserAppModel('RPM', 'Access.RPM'));
    this.apps.push(new UserAppModel('User Management', 'Access.UserManagement'));
    this.apps.push(new UserAppModel('Accounting Portal', 'Access.AccountingPortal'));
    this.apps.push(new UserAppModel('Inspection Portal', 'Access.InspectionPortal'));
    this.apps.push(new UserAppModel('Agency Management', 'Access.AgencyManagement'));
    this.apps.push(new UserAppModel('WorkFlow Portal', 'Access.WorkFlowPortal'));
    this.apps.push(new UserAppModel('Agency Portal', 'Access.AgencyPortal'));
    this.apps.push(new UserAppModel('DMU', 'Access.DMU'));
  }

  ngOnInit() {
    this.user$ = this.store.select(fromUser.getCurrentUser);
  } 
}
