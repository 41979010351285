import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { AuthActionTypes } from './auth.actions';
import * as AuthActions from './auth.actions';
import { map, mergeMap } from 'rxjs/operators';
import { AuthService } from "./auth.service";
import { Router } from '@angular/router';
import { SharedService } from "../shared/shared.service";


@Injectable()
export class AuthEffects {

  @Effect()
  changePassword$ = this.actions$.ofType(AuthActionTypes.ChangePasswordCallAction).pipe(
    mergeMap((action: AuthActions.ChangePasswordCallAction) =>
      this.authService.changePassword(action.oldPassword, action.newPassword).pipe(
        map((response: any) => {
          if (response.isSuccess) {
            this.sharedService.showMessages("INFO", ["Your password is changed."]);
            var returnUrl = (<any>window).changePassword;
            if (returnUrl.indexOf(".") > -1) {
              (<any>window).returnUrl = returnUrl;
            }
            this.router.navigate(["/auth"]);
            return new AuthActions.ChangedPasswordCallAction();
          }
          return new AuthActions.AuthErrorAction(response.errors);
        })
      )
    )
  );

  @Effect()
  changePasswordWithKey$ = this.actions$.ofType(AuthActionTypes.ChangePasswordWithKeyAction).pipe(
    mergeMap((action: AuthActions.ChangePasswordWithKeyAction) =>
      this.authService.changePasswordWithKey(action.key, action.password).pipe(
        map((response: any) => {
          if (response.isSuccess) {
            this.sharedService.showMessages("INFO", ["Your password is changed."]);
            this.router.navigate(["/auth"]);
            return new AuthActions.ChangedPasswordWithKeyAction();
          }
          return new AuthActions.AuthErrorAction(response.errors);
        })
      )
    )
  );


  @Effect()
  logout$ = this.actions$.ofType(AuthActionTypes.LogOutAction).pipe(
    map(() => {
      window.location.href = '/account/logout';
      return new AuthActions.LoggedOutAction();
    })
  );

  constructor(private actions$: Actions, private authService: AuthService, private sharedService: SharedService, private router: Router) { }
}
