import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AdminAuthCheck } from "./shared/admin-auth-check";
import { AuthCheck } from "./shared/auth-check";
import { routes } from './app.routing';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { AuthComponent } from './auth/auth.component';
import { ClientComponent } from './client/client.component';
import { UserComponent } from './user/user.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MaterialModule } from "./shared/material.module";
import { SnackbarComponent } from './shared/snackbar/snackbar.component';
import { AccessComponent } from './access/access.component';
import { MenuComponent } from './dashboard/menu/menu.component';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app.effects';
import { UserEffects } from './user/user.effects';
import { UserModifyComponent } from './user/user-modify/user-modify.component';
import { UserAppComponent } from './user/user-modify/user-app/user-app.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { AuthEffects } from './auth/auth.effects';

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    ClientComponent,
    UserComponent,
    DashboardComponent,
    SnackbarComponent,
    SpinnerComponent,
    AccessComponent,
    MenuComponent,
    UserModifyComponent,
    UserAppComponent, ChangePasswordComponent, ForgotPasswordComponent, ResetPasswordComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    HttpClientModule,
    MaterialModule,
    FlexLayoutModule,
    RouterModule.forRoot(routes,
      { enableTracing: !environment.production }),
    StoreModule.forRoot(reducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([AppEffects, UserEffects, AuthEffects])
  ],
  providers: [AdminAuthCheck, AuthCheck],
  bootstrap: [AppComponent],

  // Dynamicly generated components should be also registered here
  entryComponents: [
    SnackbarComponent
  ]
})
export class AppModule { }
