import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthActions, AuthActionTypes } from './auth.actions';

export interface State {
  isLoading: boolean;
  errors: string[];
}

export const initialState: State = {
  isLoading: false,
  errors: []
};

export function reducer(state = initialState, action: AuthActions): State {
  switch (action.type) {

    case AuthActionTypes.ChangePasswordCallAction:
      return { ...state, isLoading: true };

    case AuthActionTypes.ChangePasswordWithKeyAction:
      return { ...state, isLoading: true };

    case AuthActionTypes.ChangedPasswordCallAction:
      return { ...state, isLoading: false };

    case AuthActionTypes.ChangedPasswordWithKeyAction:
      return { ...state, isLoading: false };

    case AuthActionTypes.AuthErrorAction:
      return { ...state, isLoading: false, errors: action.errors };

    default:
      return state;
  }
}


// Constants for selectors
export const isLoading = (state: State) => state.isLoading;
export const errors = (state: State) => state.errors;

// Selectors - Good for optimization
export const getState = createFeatureSelector<State>('auth');

export const getIsLoading = createSelector(getState, isLoading);
export const getErrors = createSelector(getState, errors);
