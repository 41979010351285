import { Action } from '@ngrx/store';
import { UserModel } from "./user.model";

export enum UserActionTypes {
  GetUsersAction = '[User] GetUsersAction',
  ReceivedUsersAction = '[User] ReceivedUsersAction',
  NewUserAction = '[User] NewUserAction',
  ModifyUserAction = '[User] ModifyUserAction',
  CreateUserAction = '[User] CreateUserAction',
  CreatedUserAction = '[User] CreatedUserAction',
  SaveUserAction = '[User] SaveUserAction',
  SavedUserAction = '[User] SavedUserAction',
  CancelUserAction = '[User] CancelUserAction',
  ErrorUserAction = '[User] ErrorUserAction'
}

export class GetUsersAction implements Action {
  readonly type = UserActionTypes.GetUsersAction;

  constructor(public searchterm: string = '', public count: number = 10) { }
}

export class ReceivedUsersAction implements Action {
  readonly type = UserActionTypes.ReceivedUsersAction;

  constructor(public users: UserModel[]) { }
}

export class NewUserAction implements Action {
  readonly type = UserActionTypes.NewUserAction;

  constructor(public user: UserModel = new UserModel()) { }
}

export class ModifyUserAction implements Action {
  readonly type = UserActionTypes.ModifyUserAction;

  constructor(public user: UserModel) { }
}


export class CreateUserAction implements Action {
  readonly type = UserActionTypes.CreateUserAction;
  constructor(public user: UserModel, public password: string) { }
}


export class CreatedUserAction implements Action {
  readonly type = UserActionTypes.CreatedUserAction;
  constructor(public user: UserModel) { }
}


export class SaveUserAction implements Action {
  readonly type = UserActionTypes.SaveUserAction;
  constructor(public user: UserModel, public password:string) { }
}


export class SavedUserAction implements Action {
  readonly type = UserActionTypes.SavedUserAction;
  constructor(public user: UserModel) { }
}

export class ErrorUserAction implements Action {
  readonly type = UserActionTypes.ErrorUserAction;
  constructor(public errors: string[]) { }
}

export class CancelUserAction implements Action {
  readonly type = UserActionTypes.CancelUserAction;
}

export type UserActions =
  GetUsersAction |
  ReceivedUsersAction |
  NewUserAction |
  ModifyUserAction |
  CreateUserAction |
  CreatedUserAction |
  SaveUserAction |
  SavedUserAction |
  CancelUserAction |
  ErrorUserAction;
