import { Component, OnInit } from '@angular/core';
import { AppState } from "../reducers/index";
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { ObservableMedia, MediaChange } from '@angular/flex-layout';

import { UserModel } from "./user.model";

import * as AppActions from './../app.actions';
import * as UserActions from './user.actions';
import * as fromUser from './user.reducer';
import { SharedService } from "../shared/shared.service";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  searchTerm: string;
  isLoading$: Observable<boolean>;
  users$: Observable<UserModel[]>;
  user$: Observable<UserModel>;
  errors$: Observable<string[]>;
  userDashboardColumns: string[] = ['name', 'username', 'email', 'userOptions'];

  watcher: Subscription;
  activeMediaQuery = '';

  constructor(private store: Store<AppState>, private sharedService: SharedService, media: ObservableMedia) {
    this.watcher = media.subscribe((change: MediaChange) => {
      this.activeMediaQuery = change ? `'${change.mqAlias}' = (${change.mediaQuery})` : '';
      if (change.mqAlias == 'xs') {
        this.setMobileColumns();
      }
      else if (change.mqAlias == 'sm') {
        this.setMobileHorizontal()
      }
      else {
        this.setAllColumns();
      }
    });

    if (media.isActive('xs')) {
      this.setMobileColumns();
    }
    else if (media.isActive('sm')) {
      this.setMobileHorizontal()
    }
    else {
      this.setAllColumns();
    }
  }

  ngOnInit() {
    this.isLoading$ = this.store.select(fromUser.getIsLoading);
    this.users$ = this.store.select(fromUser.getUsers);
    this.user$ = this.store.select(fromUser.getCurrentUser);

    this.store.dispatch(new UserActions.GetUsersAction());

    this.errors$ = this.store.select(fromUser.getErrors);
    this.errors$.subscribe(x => this.showError(x));
  }

  search() {
    this.store.dispatch(new UserActions.GetUsersAction(this.searchTerm));
  }

  backToDashboard() {
    this.store.dispatch(new AppActions.NavigateToDashboardAction());
  }

  newUser() {
    this.store.dispatch(new UserActions.NewUserAction());
  }

  edit(user: UserModel) {
    this.store.dispatch(new UserActions.ModifyUserAction(new UserModel(user)));
  }
   
  setAllColumns() {
    this.userDashboardColumns = ['name', 'username', 'email', 'userOptions'];
  }

  setMobileHorizontal() {
    this.userDashboardColumns = ['name', 'username', 'email', 'userOptions'];
  }

  setMobileColumns() {
    this.userDashboardColumns = ['email', 'userOptions'];
  }

  showError(errors: string[]): any {
    this.sharedService.showMessages('ERROR', errors);
  }
}
