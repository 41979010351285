import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers/index';
import { SharedService } from '../../shared/shared.service';
import { Observable } from 'rxjs';
import * as AuthActions from '../auth.actions';
import * as fromAuth from '../auth.reducer';

@Component({
  selector: 'auth-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  constructor(private store: Store<AppState>, private sharedService: SharedService) { }

  ngOnInit() {
    this.errors$ = this.store.select(fromAuth.getErrors);
    // Display errors if there are any
    this.errors$.subscribe(data => this.showMessages('ERROR', data));
    this.isLoading$ = this.store.select(fromAuth.getIsLoading);
  }

  // LOCALS
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;

  // Oservables
  errors$: Observable<string[]>;
  isLoading$: Observable<boolean>;

  tryChangePassword() {
    if (this.newPassword === this.newPasswordConfirm)
      this.store.dispatch(new AuthActions.ChangePasswordCallAction(this.oldPassword, this.newPassword));
    else {
      this.sharedService.showMessages('ERROR', ['Passwords don\'t match!', 'Please try again.']);
    }
  }

  logOut() {
    this.store.dispatch(new AuthActions.LogOutAction());
  }

  // Displays messages/errors types are: ERROR. INFO, SUCCESS
  showMessages(type: string, messages: string[]) {
    this.sharedService.showMessages(type, messages);
  }

  // [FORGOT PASSWORD] clicked
  forgotPassword() {
    this.store.dispatch(new AuthActions.ForgotPasswordAction());
  }

  // [NEED HELP] clicked
  needHelp() { 
    this.showMessages('INFO', ['Please contact support.']);
  }
}
