import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import * as AppActions from './../../app.actions';


@Component({
  selector: 'dashboard-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
  }


  goToUsers() {
    this.store.dispatch(new AppActions.NavigateToUsersAction());
  }
}
